<template>
  <router-header :routerHeaderInfo="routerHeaderInfo"></router-header>

  <div class="flex">
    <div class="radar-basic-info flex-1 mr-24 p-16 bg-color-f5">
      <p class=" mt-0 title-mark">基本信息</p>

      <div class="flex">
        <div class="flex-1 mr-32">
          <a-row class="flex-nowrap">
            <a-col class="content_label">雷达标题:</a-col>
            <a-col>{{radarInfo.title}}</a-col>
          </a-row>
          <a-row class="py-16">
            <a-col class="content_label">类 型:</a-col>
            <a-col>{{ radarInfo.type === appendixTypeEnum.LINK ? '链接' : 'PDF' }}</a-col>
          </a-row>
          <a-row>
            <a-col class="content_label">创建人:</a-col>
            <a-col>
              <div v-is="'ww-open-data'"
                   class="ww-open-data"
                   type="userName"
                   :openid="radarInfo.createdUserWorkWechatThirdAppUserId"></div>
            </a-col>
          </a-row>
          <a-row class="py-16">
            <a-col class="content_label">创建时间:</a-col>
            <a-col>{{ $f.datetime(radarInfo.createdTime, 'YYYY-MM-DD HH:mm') }}</a-col>
          </a-row>
        </div>

        <div class="flex-1">
          <p class="text-color-666">示例：</p>
          <template v-if="radarInfo.type === appendixTypeEnum.LINK">
            <a :href="radarInfo.content.link"
               target="_blank">
              <msg-link class="mt-16 ml-44 bg-color-f"
                        :msg="{title: radarInfo.content.linkTitle, subTitle: radarInfo.content.linkSummary, img: radarInfo.content.linkCover}"
                        @click="goLinkUrl" />
            </a>
          </template>
          <template v-if="radarInfo.type === appendixTypeEnum.FILE">
            <a :href="radarInfo.content.bucketUri"
               target="_blank">
              <msg-link class="mt-16 ml-44 bg-color-f"
                        :msg="{title: radarInfo.content.name, subTitle: renderSize(radarInfo.content.size), img: pdfImgUrl}" />
            </a>
          </template>
        </div>
      </div>
    </div>

    <div class="flex-1 p-16 bg-color-f5">
      <p class="mt-0 title-mark">统计数据</p>
      <div class="flex flex-nowrap justify-evenly pt-44">
        <div v-for="(value, key) in radarStatistics"
             :key="key">
          <a-statistic :title="statisticEnum[key]">
            <template #formatter>
              <count-to :endVal="value"
                        :duration="1000" />
            </template>
          </a-statistic>
        </div>
      </div>
    </div>
  </div>

  <div class="title-mark">客户数据</div>

  <a-table row-key="radarId"
           bordered
           :columns="columns"
           :data-source="customer"
           :pagination="pagination"
           :loading="tableLoading"
           @change="onPaginationChange">

    <template #customer="{ record }">
      <avatar-column :avatar="{avatar: record.customerAvatar, name: record.customerName}" />
    </template>

    <template #staff="{ record }">
      <avatar-column v-if="record.staffId"
                     :avatar="{avatar: record.staffAvatar, userId: record.staffWorkWechatThirdAppUserId}"
                     is-user-id />
      <span v-else> - </span>
    </template>

    <template #updatedTime="{ text }"> {{ $f.datetime(text, 'YYYY-MM-DD HH:mm') }}</template>

    <template #action="{ record }">
      <a-space :size="16">
        <a-button type="link"
                  :disabled="!record.staffId"
                  @click='openVisitHistoryModal(record.customerUnionId)'>点击详情
        </a-button>

        <router-link :to="{path:'/customer/customerDetail', query:{ customerId: record.customerId, id: record.relationId}}">
          <a-button type="link"
                    :disabled="!record.staffId">客户详情</a-button>
        </router-link>
      </a-space>
    </template>
  </a-table>

  <a-modal v-model:visible="visitHistoryModalVisble"
           title="点击详情"
           width="600px"
           :footer="null"
           :bodyStyle="{height: '320px', 'overflow-X': 'auto'}">
    <a-timeline>
      <a-timeline-item v-for="(record, index) in visitHistory.list"
                       :key="index"
                       class="text-color-333">
        <p class="mb-8 text-color-999"
           v-if="index == 0 || !isTimeSame(visitHistory.list, index)">
          {{ $f.datetime(record.visitTime, 'YYYY-MM-DD HH:mm') }}
        </p>

        <a-avatar v-if="record.customerAvatar"
                  :src="record.customerAvatar"
                  alt="客户头像"
                  shape="square"
                  :size="32" />
        <svg-icon v-else
                  class="text-32"
                  type="iconmorentouxiang" />
        <span class="ml-4 font-medium">「{{record.customerName}}」</span>
        打开了
        <a-avatar v-if="record.staffAvatar"
                  :src="record.staffAvatar"
                  alt="员工头像"
                  shape="square"
                  :size="32" />
        <svg-icon v-else
                  class="text-32"
                  type="iconmorentouxiang" />

        <span class="ml-4 font-medium">「 <div v-is="'ww-open-data'"
               class="ww-open-data"
               type="userName"
               :openid="record.staffName"></div>」</span>
        发送的商机雷达
        <span class="font-medium">「{{record.radarTitle}}」</span>
      </a-timeline-item>
    </a-timeline>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import _ from "lodash";
import { CountTo } from "vue-count-to2";
import { Avatar, Statistic, Space, Timeline } from "ant-design-vue";

import RouterHeader from "@/components/RouterHeader.vue";
import AvatarColumn from "@/components/AvatarColumn";
import SvgIcon from "@/components/SvgIcon";
import MsgLink from "@/components/MsgLink";

import { appendixTypeEnum, renderSize, getWxAgentConfig } from "@/global";
import radarApi from "@/service/api/businessRadar";
import usePaginationQuery from "@/shared/composables/usePaginationQuery";
import filters from "@/shared/service/filters";

export default defineComponent({
  name: "BusinessRadarDetail",

  components: {
    CountTo,
    AStatistic: Statistic,
    ASpace: Space,
    ATimeline: Timeline,
    ATimelineItem: Timeline.Item,
    AAvatar: Avatar,

    RouterHeader,
    AvatarColumn,
    SvgIcon,
    MsgLink,
  },

  setup() {
    const routerHeaderInfo = [
      {
        path: "/marketingUtil/businessRadar",
        name: "商机雷达",
      },
      {
        name: "雷达数据",
      },
    ];

    const route = new useRoute();
    const { id: radarId } = route.query;

    const router = useRouter();

    // 统计数据
    const radarStatistics = reactive({});

    const statisticEnum = {
      totalClickCount: "累计点击次数",
      totalCustomerCount: "累计点击人数",
      todayClickCount: "今日点击次数",
      todayCustomerCount: "今日点击人数",
    };

    async function getCountStatistics() {
      const res = await radarApi.getCountStatistics({
        radarId,
      });

      _.assign(radarStatistics, res);
    }

    // 基本信息
    const radarInfo = reactive({});

    async function getRadarDetail() {
      const res = await radarApi.get({ id: radarId });
      _.assign(radarInfo, res);
    }

    // 客户数据
    const columns = [
      {
        title: "客户",
        dataIndex: "customerId",
        slots: { customRender: "customer" },
      },
      {
        title: "所属员工",
        dataIndex: "staffId",
        slots: { customRender: "staff" },
      },
      {
        title: "最近点击时间",
        dataIndex: "updatedTime",
        slots: { customRender: "updatedTime" },
      },
      {
        title: "操作",
        key: "action",
        slots: { customRender: "action" },
      },
    ];

    const {
      rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onPaginationChange,
      tableLoading,
    } = usePaginationQuery(router, { radarId }, radarApi.findVisitRecord);

    // 点击详情
    const visitHistoryModalVisble = ref(false);

    const visitHistory = reactive({
      list: {},
    });

    async function openVisitHistoryModal(unionId) {
      visitHistory.list = await radarApi.findCustomerVisitHistory({
        radarId,
        unionId,
      });

      visitHistoryModalVisble.value = true;
    }

    function isTimeSame(record, index) {
      const previousTime = filters.datetime(
        record[index - 1].visitTime,
        "YYYY-MM-DD HH:mm"
      );
      const lastTime = filters.datetime(
        record[index].visitTime,
        "YYYY-MM-DD HH:mm"
      );

      return previousTime === lastTime;
    }

    getWxAgentConfig();
    getRadarDetail();
    getCountStatistics();

    return {
      routerHeaderInfo,
      pdfImgUrl: process.env.VUE_APP_PDF_IMAGE_URL,
      appendixTypeEnum,
      renderSize,

      radarStatistics,
      statisticEnum,

      radarInfo,

      columns,
      customer: rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onPaginationChange,
      tableLoading,

      visitHistoryModalVisble,
      visitHistory,
      openVisitHistoryModal,
      isTimeSame,
    };
  },
});
</script>
<style lang='less' scoped>
.content_label {
  padding-right: 8px;
  min-width: 100px;
  color: @gray-dark;
  text-align: right;
}
</style>